h2 {
    letter-spacing: -0.055em;
    line-height: 88px;
    font-size: 85px;
    color: rgba(8, 8, 8, 1);
    font-family: gt-super, Georgia, Cambria, "Times New Roman", Times, serif;
    font-weight: 400;
}

h3 {
    font-size: 24px;
    line-height: 24px;
    color: rgba(41, 41, 41, 1);
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
}